// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}

.btn-light{
  border: 1px solid darken($light, 2%);
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-outline-dark, .btn-dark{
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover{
    color: $light;
  }
}

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        color: $value;
        background-color: rgba(($value), 0.25);
        &:hover{
          color: $white;
          background-color: ($value);
        }
    }
}

//
/* Social Buttons */

.btn-social-icon .btn{
  width: 36px;
  height: 36px;
  padding-left: 10px;
}
.btn-facebook {
  color: $white !important;
  background-color: #3b5998;
}
.btn-twitter {
  color: $white !important;
  background-color: #00aced;
}
.btn-linkedin {
  color: $white !important;
  background-color: #007bb6;
}
.btn-googleplus {
  color: $white !important;
  background-color: #dd4b39;
}
.btn-instagram {
  color: $white !important;
  background-color: #517fa4;
}
